import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
// import { create } from 'domain';
import {store} from './store';
import Keycloak from 'keycloak-js';
//import { KeycloakProvider } from '@react-keycloak/web';

// Prod
const keycloak: Keycloak.KeycloakInstance = Keycloak();

keycloak.init({ onLoad: 'login-required' })
 .success(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App keycloak={keycloak} />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}).error(error => console.log(error));

// Dev
// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
