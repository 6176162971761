import {UPDATE_MAIN_ASSESSMENT, UPDATE_MINMAX_ASSESSMENT, UPDATE_PROGNOSIS_SHORTTERM, UPDATE_PROGNOSIS_LONGTERM, 
    UPDATE_PROGNOSIS_SHORTTERM_COMMENT, UPDATE_PROGNOSIS_LONGTERM_COMMENT, UPDATE_ACTION_SHORTTERM, UPDATE_ACTION_LONGTERM, UPDATE_IMPACT_FACTORS, ADD_IMPACT_FACTOR, UPDATE_TYPE} from '../constants/assessment';
import {AssessmentTypes} from '../actions/assessment';
//import { Reducer } from 'react';
import {AssessmentState} from '../store/types';
import {ImpactFactorValue} from '../interface/impactfactor';


const initialState: AssessmentState = {
    type: 0, 
    assessmentMain: 6,
    assessmentMin: 3,
    assessmentMax: 8,
    prognosisShortterm: 0,
    prognosisLongterm: 0,
    prognosisShorttermComment: '',
    prognosisLongtermComment: '',
    actionsShortterm: '',
    actionsLongterm: '',
    impactfactors: [],
    // impactfactors: [{key:"anxiety", value: 0, comment: ""}, {key:"workload", value: 0, comment: ""}, {key:"moral", value: 0, comment: ""}, {key:"protection-equippment", value: 0, comment: ""}, {key:"other", value: 0, comment: ""}],
}

function rootReducer(state = initialState, action: AssessmentTypes) {    
    if (action.type === UPDATE_MAIN_ASSESSMENT) {
      return Object.assign({}, state, {
        assessmentMain: action.payload
      });
    } else if (action.type === UPDATE_MINMAX_ASSESSMENT) {
      let temp = [3, 8];
      if(action.payload) {
        temp = action.payload;
      }
      return Object.assign({}, state, {
        assessmentMin: temp[0],
        assessmentMax: temp[1],    
      });
    } else if (action.type === UPDATE_PROGNOSIS_SHORTTERM) {
        return Object.assign({}, state, {
          prognosisShortterm: action.payload
        });
    } else if (action.type === UPDATE_PROGNOSIS_SHORTTERM_COMMENT) {
        return Object.assign({}, state, {
          prognosisShorttermComment: action.payload
        });
    } else if (action.type === UPDATE_IMPACT_FACTORS) {
      let temp = state.impactfactors;  
      //state.impactfactors.forEach((value: number, key: string) =>{
      //state.impactfactors.forEach((key: string, value: number) =>{
      for(let i=0; i < state.impactfactors.length; i++){
        let key = state.impactfactors[i].key;
        if(action.payload?.key == key) {
          temp[i] = {key: key, value:action.payload.value as number, comment: action.payload.comment, commentVisible: action.payload.commentVisible, impactFactorId: action.payload.impactFactorId, displayName: action.payload.displayName}
          // temp[i] = {key: key, value:action.payload.get(key) as number}
          //temp.set(key, action.payload.get(key) as number);
        }
      };
      return Object.assign({}, state, {
        impactfactors: temp
      });
    } else if (action.type === UPDATE_PROGNOSIS_LONGTERM) {
        return Object.assign({}, state, {
          prognosisLongterm: action.payload
        });
    } else if (action.type === UPDATE_PROGNOSIS_LONGTERM_COMMENT) {
        return Object.assign({}, state, {
          prognosisLongtermComment: action.payload
        });
    } else if (action.type === UPDATE_ACTION_SHORTTERM) {
        return Object.assign({}, state, {
          actionsShortterm: action.payload
        });
    } else if (action.type === UPDATE_ACTION_LONGTERM) {
        return Object.assign({}, state, {
          actionsLongterm: action.payload
        });
    } else if (action.type === ADD_IMPACT_FACTOR) {
      let temp = state.impactfactors;
      temp.push(action.payload as ImpactFactorValue);
      return Object.assign({}, state, {
        impactfactors: temp
      });
    } else if (action.type === UPDATE_TYPE) {
      return Object.assign({}, state, {
        type: action.payload
      });
    }
    return state;
  }

export default rootReducer;
