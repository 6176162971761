import React from 'react';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import { RadioGroup, FormControlLabel } from '@material-ui/core';
import { store } from '../store';
import {updatePrognosisShortterm, updatePrognosisLongterm, updatePrognosisShorttermComment, updatePrognosisLongtermComment} from '../actions/assessment';

interface IProp {
    title: string;
    header: string;
    prognosisShortterm: string;
    prognosisLongtterm: string;
    prognosisShorttermComment: string;
    prognosisLongttermComment: string;
}

interface IState {
    header: string;
    prognosisShortterm: string;
    prognosisLongtterm: string;
    prognosisShorttermComment: string;
    prognosisLongttermComment: string;
}

class Prognosis extends React.Component<IProp, IState> {

    constructor(props: IProp) {
        super(props);

        this.state = {
            header: this.props.header,
            prognosisShortterm: this.props.prognosisShortterm,
            prognosisLongtterm: this.props.prognosisLongtterm,
            prognosisShorttermComment: this.props.prognosisShorttermComment,
            prognosisLongttermComment: this.props.prognosisLongttermComment,
        };
    }

    //const [selectedValue, setSelectedValue] = React.useState('a');

    handleSTValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            prognosisShortterm: event.target.value
        });
        store.dispatch(updatePrognosisShortterm(event.target.value));
    };

    handleSTCommentValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            prognosisShorttermComment: event.target.value
        });
        store.dispatch(updatePrognosisShorttermComment(event.target.value));
    };

    handleLTValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            prognosisLongtterm: event.target.value
        });
        store.dispatch(updatePrognosisLongterm(event.target.value));
    };

    handleLTCommentValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            prognosisLongttermComment: event.target.value
        });
        store.dispatch(updatePrognosisLongtermComment(event.target.value));
    };

    public render() {
        return(
            <div>
                <p>{this.state.header}</p>
                <h4>Kommande vecka</h4>
                <RadioGroup aria-label="Prognos kort sikt " name="prognosisshortterm" value={this.state.prognosisShortterm} onChange={this.handleSTValueChange}>
                    <FormControlLabel value="2" control={<Radio />} label="Mycket bättre" />
                    <FormControlLabel value="1" control={<Radio />} label="Bättre" />
                    <FormControlLabel value="0" control={<Radio />} label="Samma" />
                    <FormControlLabel value="-1" control={<Radio />} label="Sämre" />
                    <FormControlLabel value="-2" control={<Radio />} label="Mycket sämre" />
                    <TextField
                        id="outlined-multiline-static"
                        label="Kommentar kort sikt"
                        multiline
                        rows={4}
                        defaultValue={this.state.prognosisShorttermComment}
                        // defaultValue=""
                        variant="outlined"
                        onChange={this.handleSTCommentValueChange}
                    />
                </RadioGroup>
                <h4>Längre tid</h4>
                <RadioGroup aria-label="Prognos lång sikt" name="prognosislongtterm" value={this.state.prognosisLongtterm} onChange={this.handleLTValueChange}>
                    <FormControlLabel value="2" control={<Radio />} label="Mycket bättre" />
                    <FormControlLabel value="1" control={<Radio />} label="Bättre" />
                    <FormControlLabel value="0" control={<Radio />} label="Samma" />
                    <FormControlLabel value="-1" control={<Radio />} label="Sämre" />
                    <FormControlLabel value="-2" control={<Radio />} label="Mycket sämre" />
                    <TextField
                        id="outlined-multiline-static"
                        label="Kommentar lång sikt"
                        multiline
                        rows={4}
                        defaultValue={this.state.prognosisLongttermComment}
                        variant="outlined"
                        onChange={this.handleLTCommentValueChange}
                    />
                </RadioGroup>
                {/* <PrognosisItem title={"Kort sikt"} />
                <PrognosisItem title={"Lång sikt"} /> */}
            </div>
        );
    }
}

export default Prognosis;