import React from 'react';
import { RadioGroup, FormControlLabel, Radio, TextField } from '@material-ui/core';
import { store } from '../store';
import { updateImpactFactor } from '../actions/assessment';
import { ImpactFactorValue } from '../interface/impactfactor';

interface IProp {
    title: string;
    id: string;
    value: string;
    comment: string;
    displayName: string;
    commentVisible: boolean;
    impactFactorId: string;
}

interface IState {
    title: string;
    id: string;
    value: string;
    comment: string;
    displayName: string;
    commentVisible: boolean;
    impactFactorId: string;
}

const style = {
    display: 'block', 
    'margin-bottom': '10px',
};

class ImpactFactorItem extends React.Component<IProp, IState> {

    constructor(props: IProp) {
        super(props);
        this.state = {
            title: this.props.title,
            id: this.props.id,
            value: this.props.value,
            comment: this.props.comment,
            displayName: this.props.displayName,
            commentVisible: this.props.commentVisible,
            impactFactorId: this.props.impactFactorId
        };
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {        
        this.setState({
            value: event.target.value
        });
        // let temp:Map<string, number> = new Map();
        let temp:ImpactFactorValue = {key:this.state.id, value: +event.target.value, comment: this.state.comment, commentVisible: this.state.commentVisible, impactFactorId: this.state.impactFactorId, displayName: this.state.displayName};
        // temp.set(this.state.id, +event.target.value);
        store.dispatch(updateImpactFactor(temp));
    };

    handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value);
        
        this.setState({
            comment: event.target.value
        });
        let temp:ImpactFactorValue = {key:this.state.id, value: +this.state.value, comment: event.target.value, commentVisible: this.state.commentVisible, impactFactorId: this.state.impactFactorId, displayName: this.state.displayName};
        store.dispatch(updateImpactFactor(temp));
    };

    render() {
        return (
            <div>
                <h4>{this.state.title}</h4>
                <RadioGroup aria-label="gender" style={style} name="gender1" value={this.state.value} onChange={this.handleChange}>
                <FormControlLabel value="2" control={<Radio />} label="Stor" />
                <FormControlLabel value="1" control={<Radio />} label="Viss" />
                <FormControlLabel value="0" control={<Radio />} label="Ingen" />
                </RadioGroup>
                {this.state.commentVisible &&        
                <TextField
                        id="outlined-static"
                        label="Kommentar"
                        defaultValue={this.state.comment}
                        variant="outlined"
                        onChange={this.handleChangeText}
                />}    
            </div>
        ); 
    }
}

export default ImpactFactorItem;