import React, { Component } from 'react';
import './App.css';
import VerticalLinearStepper from './components/VerticalLinearStepper';
import Keycloak from 'keycloak-js';
import { store } from './store';
import { addImpactFactor, updateType } from './actions/assessment';
import { ImpactFactorValue } from './interface/impactfactor';
import Config from './pweltconfig.json';

interface PweltConfig{
    REACT_APP_API_URL: string;
}

//function App() {

  interface IProp {
    keycloak?: Keycloak.KeycloakInstance;
  }

interface IState {
  keycloak?: Keycloak.KeycloakInstance;
  loading: boolean;
  error: boolean;
  eventId: String;
  apiBaseUrl: string;
}

class App extends React.Component<IProp, IState> {

  constructor(props: IProp) {
    super(props);
    this.state = {
      keycloak: props.keycloak,
      loading: true,
      error: false,
      eventId: "",
      apiBaseUrl: (Config as PweltConfig).REACT_APP_API_URL,
    };
  }

  public componentWillMount() {
    // if(this.state.keycloak != null) {
    // Call api to check for available assessment event  
    // var url = 'https://pweltapi.pweltapp.se/api/v1/assessmentEvent/unit/available'; 
    var url = this.state.apiBaseUrl + 'assessmentEvent/unit/available'; 
    var req = new XMLHttpRequest();
    req.open('GET', url, true);
    req.setRequestHeader('Accept', 'application/json');
    req.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    if(this.state.keycloak != null) {
      req.setRequestHeader('Authorization', 'Bearer ' + this.state.keycloak.token);
    }  
    // req.setRequestHeader('Authorization', 'Bearer ' + this.state.keycloak.token);
  
    // req.onreadystatechange = function () {
    req.onreadystatechange  = () => {
      if (req.readyState === 4) {
          if (req.status === 200) {
            var configuration = JSON.parse(req.response);
            console.log(configuration)
            console.log(configuration.configuration)
            console.log(configuration.configuration.impactFactorConfigurations);
            console.log(configuration.configuration.impactFactorConfigurations[0]);
            // TODO Use configuration for order of items later
            let other = {} as ImpactFactorValue;
            let other_found = false;
            configuration.configuration.impactFactorConfigurations.forEach((element: any) => {
              //console.log(element.displayName);
              console.log(element.impactFactor.name);
              if(element.impactFactor.name == "other") {
                other = {key:element.impactFactor.name, value: 0, comment: "", commentVisible: element.commentVisible == 1, impactFactorId: element.impactFactor.impactFactorId, displayName: element.displayName} as ImpactFactorValue;
                other_found = true;
              } else {
                let temp:ImpactFactorValue = {key:element.impactFactor.name, value: 0, comment: "", commentVisible: element.commentVisible == 1, impactFactorId: element.impactFactor.impactFactorId, displayName: element.displayName};
                store.dispatch(addImpactFactor(temp));  
              }
            });
            if(other_found) {
              store.dispatch(addImpactFactor(other));
            }
            store.dispatch(updateType(configuration.configuration.type));
            var availableEventId = configuration.assessmentEventId;
            console.log("Current eventId" + availableEventId);
            this.setState({
              loading: false,
              eventId: availableEventId,
            });
          } else {
            this.setState({
              error: true,
            });
          }
        }
    }
    req.send();
    // } else {
      // console.log(this.state.apiBaseUrl + 'assessmentEvent/unit/available');
      // this.setState({
      //   loading: false,
      //   eventId: 'test',
      // });
    // }
  }

  public submitAssessment() {
    console.log("In submit of App");
    console.log(store.getState());
    var url = this.state.apiBaseUrl + 'assessment/createUnitAssessment'; 
  
    var req = new XMLHttpRequest();
    req.open('POST', url + "/" + this.state.eventId, true);
    req.setRequestHeader('Accept', 'application/json');
    req.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    if(this.state.keycloak != null) {
      req.setRequestHeader('Authorization', 'Bearer ' + this.state.keycloak.token);
    }
    req.onreadystatechange = function () {
        if (req.readyState === 4) {
            if (req.status === 200) {
                console.log(req.responseText);
            } else {
                alert("Inskickningen misslyckades!");
            }
        } 
    }
    req.send(JSON.stringify(store.getState()));
  }

  public render() {
    console.log("In render");
    return (
      <div className="App">
        {this.state.loading && !this.state.error && <div>Laddar...</div>}
        {!this.state.loading && !this.state.error && 
          <VerticalLinearStepper handleSubmit={this.submitAssessment.bind(this)} />
        }
        {this.state.error && <div>Du har ingen skattning att göra!</div>}
          {/* <VerticalLinearStepper handleSubmit={this.submitAssessment.bind(this)} /> */}
      </div>
    );  
  }
}

export default App;
