import React from 'react';
import { Handle } from 'rc-slider';
import 'rc-slider/assets/index.css';
import Tooltip from 'rc-tooltip';
import { Slider } from '@material-ui/core';
import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import AssessmentInfoComponent from './AssessmentInfoComponent';
import { store } from '../store';
import { updateMainAssessment } from '../actions/assessment';

interface IProp {
    title: string;
    header: string;
    texts: string[];
    currentIndex: number;
}

interface IState {
    title: string;
    header: string;
    value: number;
    texts: string[];
    currentIndex: number;
    marks: {};
}

const marks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 9,
      label: '9',
    },
    {
      value: 10,
      label: '10',
    },
  ];

const handle = (props:any) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={value}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
  };

function valuetext(value: number) {
    return `${value}°C`;
}

// const handleChange = (event: any, newValue: number | number[]) => {
//   console.log(newValue);
// };
const MainSlider = withStyles({
  root: {
    //color: '#52af77',
    color: "rgba(20, 20, 20, 0.3)",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    color:"#222",
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 10,
    borderRadius: 4,
  },
  rail: {
    height: 10,
    borderRadius: 4,
    background: "linear-gradient(to right, red 40%, orange 40% 60%, green 60%);",
  },
})(Slider);

//const

class MainAssessment extends React.Component<IProp, IState> {

    constructor(props: IProp) {
        super(props);
        this.state = {
            title: props.title,
            header: props.header,
            value: 6,
            currentIndex: props.currentIndex,
            texts: props.texts,
            marks: {1: '1', 2: '2', 3: '3', 4: '4', 5: '5', 6: '6', 7: '7', 8: '8', 9: '9', 10: '10'}
        };
    }

    onSliderChange(event: object) {
        console.log(event);
    }

    handleChange = (event: any, newValue: number | number[]) => {
      this.setState({
        value: newValue as number,
        currentIndex: newValue as number
      });
      store.dispatch(updateMainAssessment(newValue as number));
    };
      
    render() {
        return (
            <div>
                <p className="assessment-title">{this.state.header}</p>
                <div className="assessment-slider">
                    <MainSlider
                        defaultValue={this.state.currentIndex}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-always"
                        step={1}
                        marks={marks}
                        valueLabelDisplay="on"
                        min={1}
                        max={10}
                        onChangeCommitted={this.handleChange}
                        />
                    <AssessmentInfoComponent texts={this.state.texts} currentIndex={this.state.currentIndex-1} />
                </div>
            </div>
        ); 
    }
}

export default MainAssessment;