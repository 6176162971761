import React from 'react';
import TextField from '@material-ui/core/TextField';
import { updateActionShortterm, updateActionLongterm } from '../actions/assessment';
import { store } from '../store';

interface IProp {
    title: string;
    header: string;
    shorttermAction: string;
    longtermAction: string;
}

interface IState {
    title: string;
    header: string;
    shorttermAction: string;
    longtermAction: string;
}

class Actions extends React.Component<IProp, IState> {

    constructor(props: IProp) {
        super(props);

        this.state = {
            title: this.props.title,
            header: this.props.header,
            shorttermAction: this.props.shorttermAction,
            longtermAction: this.props.longtermAction,
        };
    }

    handleShorttermActionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            shorttermAction: event.target.value
        });
        store.dispatch(updateActionShortterm(event.target.value));
    };

    handleLongtermActionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            longtermAction: event.target.value
        });
        store.dispatch(updateActionLongterm(event.target.value));
    };

    public render() {
        return(
            <div>
                <p>{this.state.header}</p>
                <div>
                <TextField
                    id="outlined-multiline-static"
                    label="Åtgärder kort sikt"
                    multiline
                    rows={4}
                    defaultValue={this.state.shorttermAction}
                    variant="outlined"
                    style={{marginBottom: "15px"}}
                    onChange={this.handleShorttermActionChange}
                    />
                    <div></div>
                <TextField
                    id="outlined-multiline-static"
                    label="Åtgärder lång sikt"
                    multiline
                    rows={4}
                    defaultValue={this.state.longtermAction}
                    variant="outlined"
                    style={{marginBottom: "15px"}}
                    onChange={this.handleLongtermActionChange}
                    />
                </div>
            </div>
        );
    }
}

export default Actions;