import React from 'react';
import ImpactFactorItem from './ImpactFactorItem';
import {ImpactFactorValue} from "../interface/impactfactor";

interface IProp {
    title: string;
    header: string;
    impactFactors: ImpactFactorValue[];
}

interface IState {
    title: string;
    header: string;
    impactFactors: ImpactFactorValue[];
}

class ImpactFactor extends React.Component<IProp, IState> {

    constructor(props: IProp) {
        super(props);

        this.state = {
            title: this.props.title,
            header: this.props.header,
            impactFactors: this.props.impactFactors,
        };
    }

    render() {
        var itemsList = this.state.impactFactors.map(function(impactFactor){
            return <ImpactFactorItem title={impactFactor.displayName} value={"" + impactFactor.value} id={impactFactor.key} comment={impactFactor.comment}  commentVisible={impactFactor.commentVisible} impactFactorId={impactFactor.impactFactorId} displayName={impactFactor.displayName}/>
        })
        return (
            <div>
                <div className="assessment-title">{this.state.header}</div>
                {/* <ImpactFactorItem title={'Ångest/oro'} value={"0"} id={'anxiety'} />
                <ImpactFactorItem title={'Arbetsbelastning'} value={"0"} id={'workload'} />
                <ImpactFactorItem title={'Moral'} value={"0"} id={'moral'} />
                <ImpactFactorItem title={'Skyddsutrustning'} value={"0"} id={'protection-equippment'} />
                <ImpactFactorItem title={'Annat'} value={"0"} id={'other'} /> */}
                { itemsList }
            </div>
        ); 
    }
}

export default ImpactFactor;