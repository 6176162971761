export const UPDATE_MAIN_ASSESSMENT = 'UPDATE_MAIN_ASSESSMENT';

export const UPDATE_MINMAX_ASSESSMENT = 'UPDATE_MINMAX_ASSESSMENT';

export const UPDATE_PROGNOSIS_SHORTTERM = 'UPDATE_PROGNOSIS_SHORTTERM';

export const UPDATE_PROGNOSIS_SHORTTERM_COMMENT = 'UPDATE_PROGNOSIS_SHORTTERM_COMMENT';

export const UPDATE_PROGNOSIS_LONGTERM = 'UPDATE_PROGNOSIS_LONGTERM';

export const UPDATE_PROGNOSIS_LONGTERM_COMMENT = 'UPDATE_PROGNOSIS_LONGTERM_COMMENT';

export const UPDATE_IMPACT_FACTORS = 'UPDATE_IMPACT_FACTORS';

export const UPDATE_ACTION_SHORTTERM = 'UPDATE_ACTION_SHORTTERM';

export const UPDATE_ACTION_LONGTERM = 'UPDATE_ACTION_LONGTERM';

export const ADD_IMPACT_FACTOR = 'ADD_IMPACT_FACTOR';

export const UPDATE_TYPE = 'UPDATE_TYPE';

