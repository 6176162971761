import React from 'react';
import { Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import AssessmentInfoComponent from './AssessmentInfoComponent';
import { store } from '../store';
import { updateMinMaxAssessment } from '../actions/assessment';

interface IProp {
   header: string;
   value: number[];
    texts: string[];
}

interface IState {
    header: string;
    value: number[];
    texts: string[];
}

const marks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 9,
      label: '9',
    },
    {
      value: 10,
      label: '10',
    },
  ];

const MinMaxSlider = withStyles({
    // root: {
    //   color: '#3a8589',
    //   height: 3,
    //   padding: '13px 0',
    // },
    // thumb: {
    //   height: 27,
    //   width: 27,
    //   backgroundColor: '#fff',
    //   border: '1px solid currentColor',
    //   marginTop: -12,
    //   marginLeft: -13,
    //   boxShadow: '#ebebeb 0px 2px 2px',
    //   '&:focus, &:hover, &$active': {
    //     boxShadow: '#ccc 0px 2px 3px 1px',
    //   },
    //   '& .bar': {
    //     // display: inline-block !important;
    //     height: 9,
    //     width: 1,
    //     backgroundColor: 'currentColor',
    //     marginLeft: 1,
    //     marginRight: 1,
    //   },
    // },
    root: {
      // color: '#52af77',
      color: "rgba(20, 20, 20, 0.3)",
      height: 8,
    },  
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      color:"#222",
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 10,
      borderRadius: 4,
    },
    rail: {
      height: 10,
      borderRadius: 4,
      background: "linear-gradient(to right, red 40%, orange 40% 60%, green 60%);",
    },
  
    // track: {
    //   height: 3,
    // },
    // rail: {
    //   color: '#d8d8d8',
    //   opacity: 1,
    //   height: 3,
    // },
  })(Slider);

function valuetext(value: number) {
  return `${value}°C`;
}

function AirbnbThumbComponent(props: any) {
    return (
        <span {...props}>
        <span className="bar" />
        <span className="bar" />
        <span className="bar" />
        </span>
    );
}

class MinMaxAssessment extends React.Component<IProp, IState> {

    constructor(props: IProp) {
        super(props);
        this.state = {
            // title: props.title,
            header: props.header,
            // value: [3, 8],
            // marks: {1: '1', 2: '2', 3: '3', 4: '4', 5: '5', 6: '6', 7: '7', 8: '8', 9: '9', 10: '10'}
            texts: props.texts,
            value: props.value,
        };
    }

    handleChange = (event: any, newValue: number | number[]) => {
        this.setState({
          value: newValue as number[],
        });
        store.dispatch(updateMinMaxAssessment(newValue as number[]));
      };  

    render() {
        return (
            <div>
                <p className="assessment-title">{this.state.header}</p>
                <div className="assessment-slider">
                    <MinMaxSlider 
                        //ThumbComponent={AirbnbThumbComponent}
                        getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                        defaultValue={this.state.value}
                        // defaultValue={[4, 8]}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-always"
                        // step={1}
                        marks={marks}
                        valueLabelDisplay="on"
                        min={1}
                        max={10}
                        onChangeCommitted={this.handleChange}                    
                    />
                    <span>Min:</span>
                    <AssessmentInfoComponent texts={this.state.texts} currentIndex={this.state.value[0]-1} />
                    <span>Max:</span>
                    <AssessmentInfoComponent texts={this.state.texts} currentIndex={this.state.value[1]-1} />
                </div>
            </div>
        ); 
    }
}

export default MinMaxAssessment;