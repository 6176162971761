import React from 'react';

interface IProp {
    texts: string[];
    currentIndex: number;
}  

interface IState {
    texts: string[];
    currentIndex: number;
}  

class AssessmentInfoComponent extends React.Component<IProp, IState> {

    constructor(props:IProp) {
        super(props);
        this.state = {
            texts: props.texts,
            currentIndex: props.currentIndex
        }
    }

    componentDidUpdate() {
        // console.log("I updated");
    }

    render() {
        return (
            <div>
                <span>{this.props.texts[this.props.currentIndex]}</span>                    
            </div>
        ); 
    }


}

export default AssessmentInfoComponent;